/* eslint-disable import/no-unresolved */
import CardList from '@components/CardList'
import { Main } from '@layout'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Box, Heading } from 'theme-ui'

export const Blog = () => {
  const { allArticle } = useStaticQuery(recentPostsQuery)

  return (
    <Box
      sx={{
        minHeight: '190px',
        padding: ['48px 36px', null, '88px 36px'],
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Main style={{ margin: '0 auto', padding: 0 }} variant="compact">
        <Heading
          sx={{
            textAlign: 'left',
            textTransform: 'uppercase',
            fontWeight: 400,
            marginBottom: '36px',
          }}
        >
          On the Blog
        </Heading>

        <CardList
          columns={[1, 1, 3]}
          limit={3}
          loading="eager"
          nodes={allArticle.nodes}
          variant="horizontal-aside"
        />
      </Main>
    </Box>
  )
}

const recentPostsQuery = graphql`
  query recentPostsQuery {
    allArticle(
      filter: { private: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: 3
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }
  }
`
