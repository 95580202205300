import { useTheme } from '@guess-the-rose/web-shared'
/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Heading, Text } from 'theme-ui'

import { Step1, Step2, Step3, Step4 } from './Icons'
import { withChakra } from './withChakra'

const IconTextBlurb = ({ icon: Icon, header, blurb }) => {
  return (
    <Box
      sx={{
        width: '225px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 15px 50px',
      }}
    >
      <Icon />
      <Text
        sx={{
          fontWeight: 'bold',
          color: 'white',
          fontSize: '16px',
          marginBottom: '10px',
          marginTop: '20px',
          textAlign: 'center',
        }}
      >
        {header}
      </Text>
      <Text
        sx={{
          color: 'white',
          fontSize: '14px',
          lineHeight: 1.2,
          textAlign: 'center',
        }}
      >
        {blurb}
      </Text>
    </Box>
  )
}

const IconTextCollection = ({ blurbs }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {blurbs.map((b, i) => {
        return <IconTextBlurb key={i} {...b} />
      })}
    </Box>
  )
}

export const GetStarted = withChakra(() => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        minHeight: '190px',
        padding: '52px 36px',
        background: theme.colors.brand.sectionSecondaryBackground,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Main style={{ margin: '0 auto' }} variant="compact">
        <Box>
          <Heading
            sx={{
              color: 'white',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 400,
              marginBottom: '36px',
            }}
          >
            Get Started
          </Heading>

          <IconTextCollection
            blurbs={[
              {
                icon: Step1,
                header: 'Create a Group or Join One',
                blurb:
                  'Create a group for your friends, family, or coworkers. Or, test your predictive powers against other bachelor fanatics in one of our public groups.',
              },
              {
                icon: Step2,
                header: 'Create Your Entry',
                blurb:
                  "Once you’re in a group, create your own entry and give it a nifty name. From here, you're good to go until the show starts.",
              },
              {
                icon: Step3,
                header: 'Invite Your Friends',
                blurb:
                  'Every league is given a special link that you can share to get more people to join in the league. The more the merrier!',
              },
              {
                icon: Step4,
                header: 'Make Predictions Weekly',
                blurb:
                  "Come back each week to see how you're doing and to make your picks for the following week. Good luck!",
              },
            ]}
          />
        </Box>
      </Main>
    </Box>
  )
})
