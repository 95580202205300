/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import Seo from '@widgets/Seo'
import React, { useEffect, useState } from 'react'
import { Box } from 'theme-ui'

import { About } from '../components/About'
import { Blog } from '../components/Blog'
import { Countdown } from '../components/Countdown'
import { FAQs } from '../components/FAQs'
import { FooterCTA } from '../components/FooterCTA'
import { GetStarted } from '../components/GetStarted'
import { Hero } from '../components/Hero'
import { LoneRose } from '../components/LoneRose'

const IndexPage = () => {
  const [meta, setMeta] = useState()

  useEffect(() => {
    const fetchMeta = async () =>
      fetch(
        'https://firestore.googleapis.com/v1/projects/guess-the-rose/databases/(default)/documents/meta/meta',
      )
        .then((resp) => resp.json())
        .then((resp) => {
          setMeta(resp)
        })

    fetchMeta()
  }, [])

  return (
    <>
      <Seo
        otherTags={[
          {
            content: '135a4a53a972ac9336f7bda3a218a4cc',
            name: 'p:domain_verify',
          },
        ]}
      ></Seo>
      <Hero meta={meta} />
      {meta &&
      meta.fields &&
      meta.fields.currentSeasonStatus &&
      meta.fields.currentSeasonStatus.stringValue === 'preseason' ? (
        <Countdown />
      ) : null}

      <GetStarted />
      <About />
      <Main style={{ padding: '0' }} variant="compact">
        <Box
          sx={{
            padding: ['0 36px', null, null, 0],
          }}
        >
          <hr style={{ borderTop: '1px solid #CACACA' }} />
        </Box>
      </Main>
      <FAQs />
      <Main style={{ padding: '0' }} variant="compact">
        <Box
          sx={{
            padding: ['0 36px', null, null, 0],
          }}
        >
          <hr style={{ borderTop: '1px solid #CACACA' }} />
        </Box>
      </Main>
      <Blog />
      <LoneRose />
      <FooterCTA />
    </>
  )
}

export default IndexPage
