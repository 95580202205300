import './Hero.css'

import { APP_HOME_PREFIXED_PATH, APP_URL_PATH } from '@guess-the-rose/core'
/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Heading, Image, Text } from 'theme-ui'

import HeroImage from '../images/heroImage.png'
import RoseLeft from '../images/roseLeft.png'
import RoseRight from '../images/roseRight.png'
import { Button } from './Button'

export const Hero = ({ meta }) => {
  return (
    <Main style={{ margin: '0 auto' }}>
      <Box
        sx={{
          minHeight: '560px',
          padding: '24px',
          display: 'flex',
          flexDirection: ['column', null, null, 'row'],
          alignItems: 'center',
          margin: ['0 auto', null, null, 0],
        }}
      >
        <Box
          sx={{
            minHeight: ['334px', null, '334px'],
            minWidth: ['90%', null, '571px'],
            margin: ['0 auto 50px', null, null, '0 160px 0 0'],
            border: '1px solid #F0F0F0',
            borderRadius: '5px',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '50px 1rem 50px 1rem',
            overflow: 'hidden',
          }}
        >
          <Heading
            as="h1"
            sx={{
              textTransform: 'uppercase',
              textAlign: 'center',
              fontSize: '21px',
              letterSpacing: '2.5px',
              maxWidth: '334px',
              margin: '0 auto 16px',
            }}
          >
            The free bachelor fantasy league.
          </Heading>
          <Text
            sx={{
              maxWidth: '170px',
              textAlign: 'center',
              margin: '0 auto',
              marginBottom: '16px',
              fontSize: '14px',
              lineHeight: 1.2,
            }}
          >
            {meta &&
            meta.fields &&
            meta.fields.currentSeasonStatus &&
            meta.fields.currentSeasonStatus.stringValue === 'preseason'
              ? 'Bachelorette bracket leagues now open for season 16!'
              : 'Leagues are closed for this season. Check back before the next season starts!'}
          </Text>
          <Button
            as={'a'}
            href={APP_HOME_PREFIXED_PATH}
            style={{
              marginBottom: '16px',
            }}
          >
            Get Started
          </Button>
          <Button
            as={'a'}
            href={`${APP_URL_PATH}/join-a-group`}
            style={{
              marginBottom: '16px',
            }}
            variant="outline"
          >
            Join a Group
          </Button>
        </Box>
        <Box
          className="heroImageContainer"
          sx={{
            position: 'relative',
          }}
        >
          <Image
            alt="Guess the Rose App Preview"
            className="heroImage"
            src={HeroImage}
            sx={{
              zIndex: 100,
              position: 'relative',
              filter: 'drop-shadow(0px 6px 8px rgba(0,0,0,.3))',
              maxWidth: '210px',
            }}
          />
          <Image
            alt="Guess the Rose Floral Arrangement"
            className="floralLeft"
            src={RoseLeft}
            sx={{
              position: 'absolute',
              top: '20%',
              right: ['35%', null, '50%'],
            }}
          />
          <Image
            alt="Guess the Rose Floral Arrangement"
            className="floralRight"
            src={RoseRight}
            sx={{
              position: 'absolute',
              bottom: '20%',
              left: ['35%', null, '50%'],
            }}
          />
        </Box>
      </Box>
    </Main>
  )
}
