/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Heading, Image, Text } from 'theme-ui'

import DesktopPreview from '../images/desktopPreview.png'

export const About = () => {
  return (
    <Box
      sx={{
        minHeight: '190px',
        padding: ['48px 0px', '48px 36px', '88px 36px'],
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Main style={{ margin: '0 auto', padding: 0 }} variant="compact">
        <Box
          sx={{
            display: ['block', 'flex'],
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              maxWidth: ['100%', null, '300px'],
              flexShrink: 0,
              marginBottom: ['50px'],
              padding: ['0 36px', 0],
            }}
          >
            <Heading
              sx={{
                textAlign: 'left',
                textTransform: 'uppercase',
                fontWeight: 400,
                marginBottom: '36px',
              }}
            >
              The Best Fantasy Experience for the Bachelor
            </Heading>
            <Text sx={{ fontSize: '14px', lineHeight: 1.4 }}>
              <b>Fresh Every Week.</b> Guess the Rose is a blend of a bachelor
              bracket and a bachelor fantasy league. After the first week,
              you’ll choose who you think will receive the final rose and root
              for them the entire season. If they get out early, don't worry!
              Each week you’ll choose a lineup of who you think will get a rose
              and make a few predictions about the episode. You're never out of
              it, and only takes 30 seconds each week.
            </Text>
          </Box>
          <Box>
            <Image
              alt="Desktop preview of Guess the Rose"
              src={DesktopPreview}
              sx={{
                width: '557px',
                objectFit: 'contain',
                maxWidth: 'inherit',
              }}
            />
          </Box>
        </Box>
      </Main>
    </Box>
  )
}
